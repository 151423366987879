import './details.css'
import React from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'

import { getOffer, offers } from 'store/offers'
import { RouteProps, Golfer } from 'const/types'
import { addInvestmentRoute, offerDetailsRoute, routes } from 'const/routes'
import { Link } from 'ui/link'
import { OfferBanner } from './OfferBanner'
import { Waitress } from 'components/waitress'
import { cn, isPast } from 'utils'
import { OfferReturnInvest } from './OfferReturnInvest'
import { QuestionAnswerList } from './QuestionAnswerList'
import Video from 'components/video'
import ShareLinks, { MAIN_PATH } from 'components/share-links'
import { Helmet } from 'react-helmet'
import { ContentViewer } from 'components/content-viewer'
import { OfferInvestmentGoal } from './OfferInvestmentGoal'
import { EnhancedProgressBar } from 'ui/progress/EnhancedProgressBar'
import {
  DEFAULT_ACCOMPLISHMENTS,
  DEFAULT_INVESTMENT_WILL_BE_USED,
  DEFAULT_PAST_CREATOR_REVENUE,
  DEFAULT_PERKS_OFFERED,
  DEFAULT_TEXT_FOR_REDACTOR,
} from 'const/list'

export const pathSelector = match => {
  if (match.path === routes.offerDetailsOuckah || match.path === routes.offerDetailsOuckahLlc) {
    return 'fffcf388-b284-11ed-9c3b-125fd4f865cc'
  }
  if (match.path === routes.offerDetailsGritSquad) {
    return '01117071-2727-41b6-1b64-ba5d08334ee8'
  }
  if (match.path === routes.offerDetailsNootropicsExpert) {
    return '222cf388-b284-11ed-9c3b-125fd4f86522'
  }
  if (match.path === routes.offerDetailsStreetCoater) {
    return '11ed3aa2-b364-11ed-a138-1249055f3aa2'
  }
  if (match.path === routes.offerDetailsTeamHold) {
    return '0d643aa2-b364-11ed-a138-1249055f6251'
  }
  if (match.path === routes.offerDetailsHavenSkin) {
    return 'c7241b41-8066-4586-81c9-843589bfc2ce'
  }
  return match.params.uuid
}

export const PureOfferDetailsPage = ({ golfer, match, getOffer }) => {
  const {
    firstName,
    totalInvestmentAmount,
    closedAt,
    investorNumber,
    statementText,
    termsISAAmount,
    returnsText,
    bootcampImage1,
    bootcampImage2,
    studentImage,
    channels,
    name,
    endedText1,
    minAmount,
    closedByTime,
    expiredText,
    offeringVideo1,
    offeringVideo2,
    offeringFormC,
    offeringImage2,
    isGoalMet,
    isMaxGoalMet,
  } = golfer || {}
  const isClosed = isPast(closedAt)
  const uuid = pathSelector(match)

  const DEFAULT_CONTENT = {
    blocks: DEFAULT_TEXT_FOR_REDACTOR.split('</p>').map(text => ({
      type: 'paragraph',
      data: { text },
    })),
  }
  const instLink =
    channels && JSON.parse(channels).filter(elem => Object.keys(elem).includes('instagram'))
  const youtubeLink =
    channels && JSON.parse(channels).filter(elem => Object.keys(elem).includes('youtube'))
  const tiktokLink =
    channels && JSON.parse(channels).filter(elem => Object.keys(elem).includes('tiktok'))
  const twitchLink =
    channels && JSON.parse(channels).filter(elem => Object.keys(elem).includes('twitch'))
  const discordLink =
    channels && JSON.parse(channels).filter(elem => Object.keys(elem).includes('discord'))
  const twitterLink =
    channels && JSON.parse(channels).filter(elem => Object.keys(elem).includes('twitter'))

  const is4on3 = name === 'Maayan Gordon' ? 'four-on-three-video' : ''
  return (
    <Waitress fn={[getOffer]}>
      <Helmet>
        <title>{`${name}`}</title>
        <meta
          name="description"
          content={`Now there's a way to invest financially in ${name}'s career as a creator and share in their success.`}
        />
        <meta name="twitter:card" content="summary" />
        <meta name="twitter:title" content={`${name}'s offer details`} />
        <meta
          name="twitter:description"
          content={`Now there's a way to invest financially in ${name}'s career as a creator and share in their success.`}
        />
        <meta name="twitter:site" content={MAIN_PATH} />
        <meta name="twitter:image" content={`${MAIN_PATH}${studentImage}`} />
        <meta property="og:type" content="website" />
        <meta property="og:site_name" content="OverSubscribe" />
        <meta property="og:title" content={`${name}'s offer details`} />
        <meta
          property="og:description"
          content={`Now there's a way to invest financially in ${name}'s career as a creator and share in their success.`}
        />
        <meta property="og:url" content={MAIN_PATH} />
        <meta property="og:image" content={`${MAIN_PATH}${studentImage}`} />
        <meta property="og:image:width" content="1920" />
        <meta property="og:image:height" content="1080" />
        <meta property="og:image:alt" content="offer" />
      </Helmet>

      <div className="offer-details">
        <OfferBanner golfer={golfer} />
        <div className="offer-top-block">
          <div className="container">
            <div className="row">
              <OfferInvestmentGoal
                totalInvestmentAmount={totalInvestmentAmount}
                termsISAAmount={minAmount}
                investorNumber={investorNumber}
                maximumGoal={termsISAAmount}
              />
              <ShareLinks
                className="col-6 col-md-4 col-lg-2 share-links"
                text={`Now there's a way to invest financially in ${firstName}'s career as a creator and share in their success.`}
                path={offerDetailsRoute({ uuid })}
              />
            </div>
          </div>
        </div>
        <div className="container">
          <div className="offer-goal">
            <EnhancedProgressBar
              className="offer-progress"
              value={totalInvestmentAmount}
              finalGoal={minAmount}
              max={termsISAAmount}
              raised
            />
          </div>
        </div>
        <OfferDetailsVideoWithNav
          className="only-mobile"
          name={name}
          offeringVideo1={offeringVideo1}
          is4on3={is4on3}
        />
        <div className="about-block">
          <h2 className="mb-30">About {name}</h2>
          <div className="channels">
            {tiktokLink && tiktokLink.length ? (
              <a
                href={tiktokLink.length ? tiktokLink[0].tiktok : '#'}
                className="link  mb-40 "
                target="_blank"
              >
                <span className="btn-tiktok" />
              </a>
            ) : null}
            {youtubeLink && youtubeLink.length ? (
              <a
                href={youtubeLink.length ? youtubeLink[0].youtube : '#'}
                className="link mb-40"
                target="_blank"
              >
                <span className="btn-youtube" />
              </a>
            ) : null}
            {instLink && instLink.length ? (
              <a
                href={instLink.length ? instLink[0].instagram : '#'}
                className="link mb-40"
                target="_blank"
              >
                <span className="btn-instagram" />
              </a>
            ) : null}
            {discordLink && discordLink.length ? (
              <a
                href={discordLink.length ? discordLink[0].discord : '#'}
                className="link mb-40"
                target="_blank"
              >
                <span className="btn-discord" />
              </a>
            ) : null}
            {twitchLink && twitchLink.length ? (
              <a
                href={twitchLink.length ? twitchLink[0].twitch : '#'}
                className="link mb-40"
                target="_blank"
              >
                <span className="btn-twitch" />
              </a>
            ) : null}
            {twitterLink && twitterLink.length ? (
              <a
                href={twitterLink.length ? twitterLink[0].twitter : '#'}
                className="link mb-40"
                target="_blank"
              >
                <span className="btn-twitter" />
              </a>
            ) : null}
          </div>
        </div>
        <div className="offer-about-experience">
          <div className="grey" />

          <div className="box offer-details-about first">
            {/* eslint-disable-next-line react/no-danger */}
            <ContentViewer
              contentKey={`offer-details/about${uuid}`}
              defaultContent={DEFAULT_CONTENT}
            />
          </div>
          <div className="box offer-details-experience" id="accomplishments">
            <h2 className="mb-50">How will the investment be used and for what period of time?</h2>

            <table className="investments-usage">
              <ContentViewer
                className="caption"
                defaultContent={{
                  blocks: [
                    {
                      type: 'paragraph',
                      data: { text: 'Funded time period: MM/DD/22 to MM/DD/YY' },
                    },
                  ],
                }}
                contentKey={`offer-details_investment-date${firstName}`}
              />
              <tbody>
                <ContentViewer
                  contentKey={`offer-details_used-list-table${firstName}`}
                  defaultContent={{
                    blocks: [
                      {
                        type: 'table',
                        data: {
                          withHeadings: false,
                          content: DEFAULT_INVESTMENT_WILL_BE_USED,
                        },
                      },
                    ],
                  }}
                />
              </tbody>
            </table>
          </div>
          <OfferDetailsVideoWithNav
            className="only-desktop"
            name={name}
            offeringVideo1={offeringVideo1}
            is4on3={is4on3}
          />
          <div className="offer-video second-video">
            {offeringImage2 ? (
              <img src={offeringImage2} className="img-responsive" alt="" />
            ) : (
              <Video
                videoClass="video"
                url={
                  offeringVideo2
                    ? offeringVideo2
                    : 'https://www.youtube.com/watch?v=Y1kLtQRsi6o&ab_channel=ouckah'
                }
                className="video-container"
              />
            )}
            <ContentViewer
              contentKey={`offer-details/second-video-title${uuid}`}
              defaultContent={DEFAULT_CONTENT}
              className="second-video-title"
            />
          </div>
        </div>
        <OfferReturnInvest
          uuid={uuid}
          text={DEFAULT_CONTENT}
          id="returns"
          offeringFormC={offeringFormC}
        />
        <div className="offer-about-experience " id="how-use">
          <div className="grey second"></div>
          <div className="offer-con box">
            <div className="container offer-use-investment">
              <h2 className="mb-50">Perks offered in addition to returns</h2>
              <div className="row first">
                <div className="col-12 col-lg-12">
                  <table className="investments-usage  second-inv perks-offered-table">
                    <tbody>
                      <ContentViewer
                        contentKey={`offer-details_perks-offered-table${firstName}`}
                        defaultContent={{
                          blocks: [
                            {
                              type: 'table',
                              data: {
                                withHeadings: false,
                                content: DEFAULT_PERKS_OFFERED,
                              },
                            },
                          ],
                        }}
                      />
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            {/* <div className="container offer-use-investment ">
              <h2>Past Creator Revenue</h2>
              <div className="row">
                <div className="col-12 col-lg-12">
                  <table className="investments-usage">
                    <tbody>
                      <ContentViewer
                        contentKey={`offer-details_revenue-table${firstName}`}
                        defaultContent={{
                          blocks: [
                            {
                              type: 'table',
                              data: {
                                withHeadings: false,
                                content: DEFAULT_PAST_CREATOR_REVENUE,
                              },
                            },
                          ],
                        }}
                      />
                    </tbody>
                  </table>
                </div>
              </div>
            </div> */}
          </div>
          <div className="images last">
            <div className="offer-img">
              <img src={bootcampImage1} className="img-responsive" alt="" />
            </div>
          </div>
          <div className="second-offer-con">
            <div className="box offer-details-experience white" id="milestones">
              <h2 className="mb-50">{name}’s milestones and accomplishments</h2>
              <table className="offer-progress-table">
                <tbody>
                  <ContentViewer
                    className="accomplishments"
                    contentKey={`offer-details_accomplishments-table${firstName}`}
                    defaultContent={{
                      blocks: [
                        {
                          type: 'table',
                          data: {
                            withHeadings: false,
                            content: DEFAULT_ACCOMPLISHMENTS,
                          },
                        },
                      ],
                    }}
                  />
                </tbody>
              </table>
            </div>
          </div>
          <div className="images">
            <div className="offer-img">
              <img src={bootcampImage2} className="img-responsive" alt="" />
            </div>
          </div>
        </div>
        <QuestionAnswerList offering={golfer} id="questions" closedByTime={closedByTime} />
        {/* {!isClosed && ( */}
        <div className="offer-inner-block text-center">
          <h3>Invest now in {name} and your future.</h3>
          <div className="mb-20">
            <small>
              {/* <TimeAgo date={closedAt} /> left */}
              {closedByTime ? expiredText : endedText1}
            </small>
          </div>
          <Link
            to={addInvestmentRoute({ uuid })}
            className={cn(closedByTime && 'disabled')}
            theme="primary"
          >
            Invest
          </Link>
        </div>
        {/* )} */}
      </div>
    </Waitress>
  )
}

PureOfferDetailsPage.propTypes = {
  accomplishments: PropTypes.arrayOf(PropTypes.array),
  getOffer: PropTypes.func.isRequired,
  golfer: Golfer,
  match: RouteProps.match,
  otherFees: PropTypes.arrayOf(PropTypes.array),
  tournamentFees: PropTypes.arrayOf(PropTypes.array),
}

export const OfferDetailsPage = withRouter(
  connect(
    (state, { match }) => {
      const uuid = pathSelector(match)
      return {
        golfer: offers.golfer(state, { uuid }),
      }
    },
    (dispatch, { match }) => ({
      getOffer: () => dispatch(getOffer(pathSelector(match))),
    }),
  )(PureOfferDetailsPage),
)

const OfferDetailsVideoWithNav = ({ is4on3, offeringVideo1, name, className }) => {
  return (
    <div className={cn('offer-video', is4on3, className)}>
      <Video
        videoClass="video"
        url={
          offeringVideo1
            ? offeringVideo1
            : 'https://www.youtube.com/watch?v=d2Oqupnd2IU&ab_channel=ouckah'
        }
        className="video-container"
      />
      <div className="page-links">
        <Link to="#accomplishments">
          How will the investment be used and for what period of time?
        </Link>
        <Link to="#returns">How will supporters make returns on investment?</Link>
        <Link to="#how-use">Perks offered in addition to returns</Link>
        <Link to="#milestones">{name}’s milestones and accomplishments</Link>
        <Link to="#questions">Q&amp;A</Link>
      </div>
    </div>
  )
}
