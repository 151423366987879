/* eslint-disable import/no-unassigned-import */
import 'core-js/stable/array/includes'
import 'core-js/stable/array/find'
import 'core-js/stable/array/from'
import 'core-js/stable/object/assign'
import 'core-js/stable/object/values'
import 'core-js/stable/object/entries'
import 'core-js/stable/object/from-entries'
import 'core-js/stable/symbol'
import 'core-js/stable/set'
import 'core-js/stable/promise'
import 'core-js/stable/url-search-params'
import 'whatwg-fetch'
/* eslint-enable import/no-unassigned-import */

import './theme.css'
import './style.css'
import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom'

import createStore from './store'
import { routes } from 'const/routes'

import { Header } from 'pages/Header'
import { Content } from 'pages/Content'
import { Footer } from 'pages/Footer'

import { LandingPage } from 'pages/landing'
import { LoginPage } from 'pages/login'
// import { SignUp } from 'pages/signup'
import { OfferDetailsPage } from 'pages/offer-details'
import { GlobalAlert } from 'components/global-alert'
import { About } from 'pages/static/about'
import { LearnMore } from 'pages/static/learn-more'
import { PrivacyPolicy } from 'pages/static/privacy-policy'
import { TermsOfUse } from 'pages/static/terms-of-use'
import { Throbber } from 'ui/throbber'
import { PasswordRecovery } from 'pages/password-recovery'
import { PasswordReset } from './pages/password-reset'
import { RecoveryCongrats } from './pages/password-recovery/congrats'
import { WhyWork } from 'pages/static/why-work'
import { ThankYouPage } from 'pages/investor/add-investment/thankYouPage'
import { StudyDetails } from 'pages/study-details'
import { MaterialChangeConfirm } from 'pages/material-change/confirm'
import { MaterialChangeCancel } from 'pages/material-change/cancel'
const SignUpPage = React.lazy(() => import(/* webpackChunkName: "signup" */ 'pages/signup'))
const AdminPage = React.lazy(() => import(/* webpackChunkName: "admin" */ 'pages/admin'))
const IssuerPage = React.lazy(() => import(/* webpackChunkName: "issuer" */ 'pages/issuer'))
const InvestorPage = React.lazy(() => import(/* webpackChunkName: "investor" */ 'pages/investor'))
const IssuerLogin = React.lazy(() =>
  import(/* webpackChunkName: "investor" */ 'pages/issuer/login-us-issuer'),
)

export const store = createStore()

export const mainElement = document.createElement('div')
mainElement.className = 'main'
document.body.appendChild(mainElement)

ReactDOM.render(
  <Provider store={store}>
    <Router>
      <Header />
      <Content>
        <React.Suspense fallback={<Throbber />}>
          <Switch>
            <Route exact path={routes.landing} component={LandingPage} />
            <Route path={routes.login} component={LoginPage} />
            <Route path={routes.signUp} component={SignUpPage} />
            <Route
              exact
              path={[
                routes.offerDetails,
                routes.offerDetailsQuestion,
                routes.offerDetailsOuckah,
                routes.offerDetailsOuckahLlc,
                routes.offerDetailsGritSquad,
                routes.offerDetailsNootropicsExpert,
                routes.offerDetailsStreetCoater,
                routes.offerDetailsTeamHold,
                routes.offerDetailsHavenSkin,
              ]}
              component={OfferDetailsPage}
            />
            <Route path={routes.about} component={About} />
            <Route path={routes.learnMore} component={LearnMore} />
            <Route path={routes.privacyPolicy} component={PrivacyPolicy} />
            <Route path={routes.termsOfUse} component={TermsOfUse} />
            <Route path={routes.whyWork} component={WhyWork} />
            <Route path={routes.thankYouPage} component={ThankYouPage} />

            <Route path={routes.issuer} component={IssuerPage} />
            <Route path={routes.investor} component={InvestorPage} />
            <Route path={routes.admin} component={AdminPage} />

            <Route exact path={routes.passwordRecoveryCongrats} component={RecoveryCongrats} />
            <Route path={routes.passwordRecovery} component={PasswordRecovery} />

            <Route path={routes.passwordReset} component={PasswordReset} />
            <Route path={routes.issuerLogin} component={IssuerLogin} />
            <Route path={routes.studyDetails} component={StudyDetails} />
            <Route path={routes.materialChangeConfirm} component={MaterialChangeConfirm} />
            <Route path={routes.materialChangeCancel} component={MaterialChangeCancel} />

            <Redirect to={routes.landing} />
          </Switch>
        </React.Suspense>
      </Content>
      <Footer />
      <GlobalAlert />
    </Router>
  </Provider>,
  mainElement,
)
